/** Example file/folder data. */
export const files = [
  {
    name: 'Last weeks event',
    type: 'message',
    children: [
      { name: 'Gareth, well enjoyed it', type: 'insert_emoticon' },
      { name: 'Cacilia, it was ok', type: 'sentiment_satisfied_alt' },
      { name: 'Steve, it was a bit meh', type: 'sentiment_satisfied_alt' },
    ]
  },
  {
    name: 'What happening at Christmas',
    type: 'message',
    children: [

        { name: 'Cacilia, I will be on holiday', type: 'sentiment_satisfied_alt' },
        { name: 'Gareth, working BOOO', type: 'sentiment_very_dissatisfied' }
    ]
  },
  {
    name: 'More......',
    type: 'message',
    children: [

        { name: 'Cacilia, I will be on holiday', type: 'sentiment_satisfied_alt' },
        { name: 'Gareth, working BOOO', type: 'sentiment_very_dissatisfied' }
    ]
  },
  {
    name: 'More......',
    type: 'message',
    children: [

        { name: 'Cacilia, I will be on holiday', type: 'sentiment_satisfied_alt' },
        { name: 'Gareth, working BOOO', type: 'sentiment_very_dissatisfied' }
    ]
  },
  {
    name: 'More......',
    type: 'message',
    children: [

        { name: 'Cacilia, I will be on holiday', type: 'sentiment_satisfied_alt' },
        { name: 'Gareth, working BOOO', type: 'sentiment_very_dissatisfied' }
    ]
  }



];
