<mat-card class="example-card">
    <mat-card-header>
        
      <div mat-card-avatar class="example-header-image">
        <mat-icon>book_online</mat-icon>
      </div>
      <mat-card-title>Appointment</mat-card-title>
      <mat-card-subtitle>Please accept</mat-card-subtitle>
    </mat-card-header>
    
    <mat-card-content>
      <p>
        If you are happy to visit the Leeds ADDRESS on the 17 December 2020 please accept this appoitment
      </p>
    </mat-card-content>
    <mat-card-actions>
      <button mat-button>Accept</button>
      <button mat-button>Decline</button>
    </mat-card-actions>
  </mat-card>